export var language = {
    'welcome': 'Double your conversion rates',
    'register': 'Register',
    'login': 'Login',
    'discount_code': 'Discount code',
    'sign_in_message': 'Sign in',
    'sign_in': 'Sign in',
    'forgot_password' : 'Forgot password',
    'create_new_account' : 'Create new account',
    'romanian': 'Romanian',
    'english': 'English',
    'password': 'Password',
    'confirm_password': 'Confirm password',
    'terms_and_conditions': 'Terms and Conditions',
    'agree': 'I agree with the',
    'not_robot': 'I am not a robot',
    'create_account': 'Create account',
    'product_stock_sync': 'Products stock sync interval (hours) ',
    'create_your_account': 'Sign up',
    'login_into_account': 'Sign in into your account',
    'and': 'and',
    'privacy_policy': 'Privacy policy',
    'profile': 'Profile',
    'profile_information': 'Profile informations',
    'company_informations': 'Company informations',
    'country': 'Country',
    'company_name': 'Company name',
    'registration_code': 'Registration code',
    'vat_number': 'VAT number',
    'trade_register_number': 'Trade register registration number',
    'office_address': 'Office address',
    'county': 'County',
    'local': 'Local',
    'address': 'Address',
    'contact_person': 'Contact person',
    'first_name': 'First name',
    'last_name': 'Last name',
    'phone_number': 'Phone number',
    'shop_url': 'Shop URL',
    'city': 'City',
    'currency': 'Currency',
    'orders' : 'Orders',
    'orders_feed' : 'Orders Feed',
    'import_orders' : 'Import orders',
    'OrderID' : 'Order ID',
    'Product' : 'Product',
    'Quantity' : 'Quantity',
    'Date' : 'Date',
    'orders_table': 'Orders Table',
    'pieces': 'Pcs',
    'order_product_price': 'Price',
    'products': 'Products',
    'product_feed': 'Products Feed URL',
    'product_feeds': 'Product Feeds',
    'product_feed_url': 'Product Feed URL',
    'import_products': 'Import Products',
    'products_table': 'Products Table',
    'identifier': 'Identifier',
    'picture': 'Picture',
    'name': 'Name',
    'category': 'Category',
    'subcategory': 'Subcategory',
    'subtype': 'Subtype',
    'price': 'Price',
    'discount': 'Discount',
    'stock_status': 'Stock Status',
    'description': 'Description',
    'rating': 'Rating',
    'product_url': 'Product URL',
    'company_url': 'Shop URL',
    'configuration': 'Configuration',
    'choose_color': 'Choose color',
    'upload_icon': 'Upload icon',
    'layout': 'Layout',
    'position': 'Position',
    'left': 'Left',
    'right': 'Right',
    'upload': 'Upload',
    'popup_content': 'Pop-up Content',
    'add_marketing_messages': 'Add marketing messages and discounts',
    'see_data': 'See data',
    'popup_display_frequency': 'Pop-up display frequency per day',
    'new_guests': 'New Guests',
    'visits': 'Visits',
    'actions_per_visit': 'Actions per visit',
    'average_visit_duration': 'Average visit duration',
    'cart_views': 'Cart views',
    'delivered_popups': 'Delivered Popups',
    'dismissed_popups': 'Dismissed popups',
    'accessed_products': 'Accessed Products',
    'discount_views': 'Discount Views',
    'since_last_month': 'Since last month',
    'top_products': 'Top products',
    'number_of_visits': 'Number of visits',
    'save': 'Save',
    'done': 'Done',
    'help': 'Here are some videos that might help you',
    'product_link': 'Link produs',
    'delete': 'Delete',
    'intro_messages': 'Intro messages',
    'select_all': 'Select all',
    'messages_discounts': 'Messages and discounts',
    'discount_messages': 'Discount messages',
    'no_products_message': 'There are no products yet.',
    'products_and_recommendations': 'Products and recommendations for each user',
    'no_recommendations': 'There are no products recommendations yet.',
    'viewed_product': 'Viewed product',
    'recommended_products': 'Recommended products',
    'accessed_recommendation': 'Accessed recommendation',
    'no_discounts': 'There are no discounts yet.',
    'recommended_discounts': 'Recommended discounts',
    'no_checkouts': 'There are no checkouts yet.',
    'number_of_checkouts': 'Number of checkouts',
    'subscription': 'Subscription',
    'invoice_history': 'Invoice history',
    'period': 'Period',
    'sum': 'sum',
    'actions': 'Actions',
    'download_invoice': 'Download invoice',
    'users': 'Users',
    'complete_user_details': 'Complete new user details',
    'add_user': 'Add user',
    'edit_user_details': 'Edit user details',
    'delete_admin_conf': 'Are you sure you want to delete this admin?',
    'yes': 'Yes',
    'no': 'No',
    'view_users': 'VIEW USERS',
    'no_users': 'There are no other users yet.',
    'edit': 'Edit',
    'fast_onboarding': 'Fast Onboarding',
    'step_1': 'Enter the following URLs from your online store',
    'step_2': 'Import your products',
    'step_3': 'Insert this code snippet in your website header',
    'step_4': 'Step 4',
    'step_5': 'Step 5',
    'step_0_text': 'Homepage URL (e.g.: myshop.com)',
    'step_1_text': 'All products URL  (e.g: myshop.com/product)',
    'step_2_text': 'Cart URL (e.g: myshop.com/cart)',
    'step_3_text': 'Checkout URL (e.g: myshop.com/checkout)',
    'step_4_text': 'Successful order page URL (Ex: myshop.com/successful-order)',
    'step_5_text': 'Insert the next code snippet in your project root',
    'feed_url': 'Products Feed URL',
    'cart_url': 'Cart URL',
    'checkout_url': 'Checkout URL',
    'success_order_url': 'Success order URL',
    'user_profile': 'User Profile',
    'settings': 'Settings',
    'reports': 'Reports',
    'pay': 'Pay',
    'logout': 'Logout',
    'change_client': 'Change client',
    'user_fingerprint': 'User fingerprint',
    'choose_client': 'Choose a client',
    'view_dashboard': 'View dashboard',
    'shop_name': 'Shop name',
    'check_email': 'Please check your email!',
    'reset_pass_email': 'We sent you an email to reset your password.',
    'enter_account_email': 'Enter your account email',
    'send_mail_password': 'Send email with new password',
    'date': 'Date',
    'product_performance': 'Product performance',
    'history_average': 'History average',
    'current_average': 'Current average',
    'product_name': 'Product',
    'google_merchant_text': 'We can easily add your products from your Google Merchant account. Do you want to start this process?',
    'insert_merchant_id': 'Please fill in your merchant id',
    'submit': 'Submit',
    'cancel': 'Cancel',
    'stores': 'Stores',
    'stores_list': 'Stores List',
    'add_new_store': 'Create a new store',
    'add_store': 'Add store',
    'store_name': 'Store name',
    'location': 'Location',
    'select_country': 'Select country',
    'select_county': 'Select county',
    'select_city': 'Select city',
    'choose_store': 'Choose store',
    'qr_code': 'QR Code',
    'are_you_sure': 'Are you sure?',
    'delete_store_text': 'This action will delete the store along with its products',
    'success': 'Success!',
    'store_deleted': 'Store deleted!',
    'store_updated': 'Store updated!'
}